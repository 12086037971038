@import '../../../general-styles/base.scss';

.carousel {
  width: 100%;
  max-width: 80rem;
  @include media-breakpoint-xwide-screen {
    max-width: 68rem;
  }

  .carousel-container {
    margin-top: $big-margin;
    width: 100%;
    box-shadow: 0 13px 21px #00000033;
    border-radius: 19px;

    .carousel-frame {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    #carousel-slider {
      top: $imac-top-border;
      overflow: hidden;
      display: grid;
      grid-template-columns: 100% 100% 100% 100% 100% 100%;
      scroll-behavior: smooth;
      z-index: 3;
      background: white;
      left: 0;
      width: 100%;
      height: auto;
      border: 10px solid white;
      border-radius: 19px;
      @include media-breakpoint-intermediate-min {
        border: 3px solid white;
        border-radius: 10px;
      }

      .slide {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transition: -webkit-transform .6s ease-in-out;
        transition: transform 1s ease-in-out;
        z-index: 3;

        img {
          width: 100%;
          height: auto;
          background: white;
          object-fit: contain;
          max-height: 624px;

          @include media-breakpoint-mobile() {
            max-height: 200px;
          }
        }
      }

      .active {
        min-width: $imac-width;
        display: block;
      }
    }

    .buttons-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      bottom: -3rem;
      position: absolute;
      border-radius: 0 0 19px 19px;
      height: 4rem;
      background: white;
      z-index: 1;
      box-shadow: 0 13px 21px #00000033;
      @include media-breakpoint-intermediate-min {
        height: 3rem;
        bottom: -2.5rem;
      }


      .walking {
        z-index: 98;
        top: 50%;
        transform: translate(-50%, -50%);
        background-image: url("../../../assets/images/dot-blue.svg");
        background-repeat: no-repeat;
        position: absolute;
        height: $indicator-size;
        width: $indicator-size;
        border-radius: 100%;
        animation-timing-function: ease-in-out;
        @include media-breakpoint-intermediate-min {
          height: $walking-indicator-size-responsive;
          width: $walking-indicator-size-responsive;
          background-position: center;
        }

        &.clicked {
          animation: indicator-animation ease-in-out 0.5s;
          transition: all 0.5s;
        }
      }

      .gray-buttons-container {
        display: flex;
        position: relative;
        align-items: center;
        width: 15rem;
        justify-content: space-between;

        @include media-breakpoint-mobile() {
          width: 10rem;
        }
        @include media-breakpoint-intermediate-min {
          width: 7rem;
        }
      }

      .circle {
        width: 0;
        height: $indicator-size;
        position: relative;
        @include media-breakpoint-intermediate-min {
          height: $indicator-size-responsive;
        }

        &:after {
          position: absolute;
          content: ' ';
          display: block;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: $indicator-size;
          height: $indicator-size;
          border-radius: 100%;
          cursor: pointer;
          background-image: url("../../../assets/images/dot-grey.svg");
          @include media-breakpoint-intermediate-min {
            height: $indicator-size-responsive;
            width: $indicator-size-responsive;
            background-position: center;
          }
        }
      }
    }
  }
}

@keyframes indicator-animation {
  0% {
    width: 0.66rem;
  }
  25% {
    width: 0.9rem;
  }
  50% {
    width: 1.5rem;
  }
  75% {
    width: 0.9rem;
  }
  100% {
    width: 0.66rem;
  }
}

