@import './base';
@import './buttons';

//default
.form-div {
  @include media-breakpoint-tablet() {
    padding: $info-margin $xbig-margin;
  }
  @include media-breakpoint-mobile() {
    padding: $info-margin $small-margin;
  }
  @include media-breakpoint-intermediate-max() {
    width: 85%;
    margin-right: auto;
    margin-left: auto;
  }
  @include media-breakpoint-intermediate-mobile() {
    width: 100%;
  }

  .section-text {
    color: $secondary-color;
    letter-spacing: -0.27px;
    margin-top: $small-size
  }

  .form {
    display: flex;
    flex-direction: column;
    margin-bottom: $info-margin;
    width: 100%;


    .apply-input, .contact-input, label, div > p {
      font-family: $font-family;
      font-size: $xsmall-size;
      color: black;
      text-align: left;
      font-weight: $font-regular;
      padding:$xsmall-margin;
      padding-left: 0;

      &.big-size-label {
        input {
          font-size: $input-big-size;

        }
      }

      input, textarea {
        font-family: $font-family;
        font-weight: $font-medium;
        font-size: $xsmall-size;
      }

      label {
        color: $secondary-color;

        @include media-breakpoint-mobile() {
          font-weight: $font-regular;
          font-size: $helper-size;
          letter-spacing: 0;
          line-height: 1rem;
        }
        @include media-breakpoint-xbig-screen() {
          letter-spacing: 0;
          line-height: 1rem;
        }
      }

      p {
        color: $error-color;
        font-weight: $font-regular;
        font-size: $helper-size;
        padding-left: 0;

      }
    }

    .long-label {
      .MuiInput-root {
        @include media-breakpoint-intermediate-min() {
          margin-top: 40px;
        }
        @include media-breakpoint-xbig-screen() {
          margin-top: 40px;
        }
      }
    }

    .MuiInput-underline:before {
      border-bottom: 1px solid $light-color
    }

    .MuiInput-underline:after {
      border-bottom: 1px solid $primary-color
    }

    .MuiInputLabel-shrink {
      transform: translate(0, 1.5px) scale(0.9);
    }

    .button {
      text-align: center;
    }
  }
}

//hover
.contact-form, .apply-form {
  div:hover {
    label {
      color: $dark-color
    }

    .MuiInput-underline:before {
      border-bottom: 1px solid $medium-color
    }
  }
}

//focused
contact-form, .apply-form {
  .MuiFormLabel-root.Mui-focused {
    color: $secondary-color;
  }
}

//dropzone
.apply-form {
  .apply-drop {
    min-height: $title-size;

    .MuiDropzoneArea-textContainer {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: center;
    }
  }
  .drop-has-error{
    .custom-dropzone{
      border: 1px dashed $error-color;
    }
  }
}


//error
.contact-form, .apply-form {
  .contact-input, .apply-input {
    &.has-error {
      .MuiInput-underline:before {
        border-bottom: 1px solid $error-color;
      }

      .MuiInput-underline:after {
        border-bottom: 1px solid $error-color;
      }
    }
  }
}
