@import '../../../general-styles/base';
@import '../../../general-styles/form';

.job-description {
  padding: 2.5rem 15rem;
  min-height: 90vh;
  align-items: center;
  @include media-breakpoint-mobile() {
    padding: $info-margin $small-margin;
  }

  .green-link {
    color: $secondary-green;
    font-size: $small-size;
    font-weight: $font-bold;
  }

  .section-title {
    margin: $small-margin 0;
    @include media-breakpoint-mobile() {
      font-size: $info-title
    }
  }

  .section-info {
    font-size: $text-size;
    color: $secondary-color;
    font-weight: $font-regular;
    margin-bottom: $small-margin;

  }

  .info-title {
    margin-top: $info-margin;
    @include media-breakpoint-mobile() {
      font-size: $input-big-size;
    }
  }

  .grey-text, li {
    color: black;
    @include media-breakpoint-mobile() {
      font-size: $helper-size;
      line-height: 1rem;
    }

  }

  ul {
    padding-left: $small-margin;

    li {
      font-size: $small-size;
      font-weight: $font-medium;
    }
  }

  .new-link {
    color: $secondary-green;
    font-weight: $font-bold;
    font-size: $xsmall-size;
    cursor: pointer;
    margin-bottom: $small-margin;
  }

  img {
    padding-right: $xsmall-margin;
  }

  .form-title {
    color: $primary-color;
    font-size: $page-title;
    font-weight: $font-bold;
  }

  .apply-form {
    margin-top: $big-margin;

    .checkbox-container {
      margin: $small-margin 0;
    }
  }

  .success-apply {
    margin-top: $xbig-margin;
    display: flex;
    flex-direction: column;
    align-items: center;

    .section-text {
      font-weight: $font-medium;
      font-size: $input-big-size;
      color: black;
      @include media-breakpoint-mobile() {
        line-height: 1.5rem;
      }
    }

    .redirect {
      color: $secondary-green;
      font-size: $small-size;
      font-weight: $font-bold;
      margin: $big-margin 0;

      img {
        margin-right: $xsmall-margin;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $light-color;
    border-radius: $radius-regular;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $secondary-color;
    border-radius: $radius-regular;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $dark-color;
    cursor: pointer;
  }
}
