@import '../../../general-styles/base.scss';

.header {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 $xxbig-margin;
  font-weight: $font-regular;
  z-index: 99;

  @include media-breakpoint-mobile() {
    padding: 0;
  }

  &.dark {
    background: transparent linear-gradient(122deg, #111B54 0%, #253AB0 100%) 0% 0% no-repeat padding-box;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    .nav-bar {
      .nav-link {
        color: white;

        &:hover {
          color: white;
        }
      }
    }
  }

  &.light {
    background-color: $background-color;
    border: 1px solid $border-color;

  }

  .header-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: $small-margin;
    height: 3rem;
  }

  a {
    display: flex;
    justify-content: center;
  }

  .hamburger-menu {
    display: none;
    @include media-breakpoint-mobile() {
      margin-left: auto;
      display: flex;
      justify-content: center;
      margin-right: 2rem;

      img {
        background-repeat: no-repeat;
        background-position: center;
        transition: all 0.2s ease-in;
        border: none;

        &.closed {
          content: url('../../../assets/images/hamburger.svg');

          &.dark {
            content: url('../../../assets/images/hamburger-dark.svg');

          }
        }

        &.opened {
          display: block;
          content: url('../../../assets/images/haamburger-close.svg');

          &.dark {
            content: url('../../../assets/images/haamburger-close-dark.svg');

          }
        }


        .mobile-menu {
          opacity: 1;
        }
      }
    }

    .mobile-menu {
      opacity: 0;
      transition: opacity 0.3s ease-in;
      margin-top: -0.7rem;
      @include media-breakpoint-mobile() {
        position: absolute;
        top: 3.7rem;
        background: white;
        right: 0;
        width: 100vw;
        box-shadow: 0 3px 6px #00000029;
        padding: $navigation-spacing;
      }

      a.nav-link {
        display: none;
      }

      &.display {
        opacity: 1;

        a.nav-link {
          display: flex;
        }
      }

      &.dark {
        background-color: #111B54;

        .nav-link {
          color: white;
        }
      }

      .nav-link {
        padding: $navigation-spacing 0;
      }
    }
  }


  .nav-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    @include media-breakpoint-mobile() {
      display: none;
    }

    .nav-link + .nav-link {
      margin-left: $small-margin;
    }

    .nav-link {
      padding: $header-margin;
      color: black;
      transition: box-shadow 0.2s, color 0.2s;
      font-size: $small-size;

      &:hover {
        box-shadow: 0px -2px 0px 0px inset $primary-color;
        color: black;
      }
    }

    .active-nav-link {
      padding: $header-margin;
      color: black;
      transition: box-shadow 0.2s, color 0.2s;
      font-size: $small-size;
      box-shadow: 0px -2px 0px 0px inset $primary-color;
    }
  }
}
