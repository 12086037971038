@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');

//base
$background-color: #F8F8F8;
$border-color: #E1E1E1E1;
$dark-border-color: #2D3884;
$primary-color: #11C9E5;
$error-color: #F43744;
$secondary-color: #959595;
$secondary-green: #23BF90;
$hover-card-background: #344464;
$drop-background: #EBEBEB;


//inputs
$light-color: #E6E6E6;
$medium-color: #D1D1D1;
$dark-color: #747474;

// Spaces
$button-padding: 0.45rem 0.8rem;
$xsmall-margin: 0.55rem; //10px
$small-margin: 1rem; //20 px
$header-margin: 1.3rem;
$info-margin: 1.5rem; //27px
$flex-gap: 1.6rem; //29px
$medium-margin: 2rem; //36px
$big-margin: 2.5rem; //45px
$xbig-margin: 4rem; // 72px
$xxbig-margin: 5.5rem; //100px
$xxl-margin: 6.7rem; //120 px
$huge-margin: 8.3rem; //150px
$form-margin: 15rem; //270px

//radius
$radius-regular: 10px;

//fonts
$font-family: Ubuntu, sans-serif;
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-bold: 700;
$big-title-size: 3.3rem;
$title-size: 2.2rem;
$page-title: 1.66rem;
$medium-size: 1.5rem;
$info-title: 1.3rem;
$input-big-size: 1.1rem;
$text-size: 0.88rem;
$small-size: 0.83rem;
$xsmall-size: 0.72rem;
$helper-size: 0.66rem;

//responsive
$big-title-mobile: 1.9rem;
$paragraph-spacing-mobile: 0.41rem;
$footer-spacing: 3.2rem;
$navigation-spacing:0.83rem;
$card-hover-size: 1rem;

//responsive values

$imac-responsive-top-border:7.8%;
$imac-responsive-left-border:3.2%;
$imac-responsive-height:83%;
$imac-responsive-width:93.3%;

$imac-top-border:4%;
$imac-left-border:2.5%;
$imac-height:82%;
$imac-width:95%;

$imac-top-border-demo:6%;
$imac-left-border-demo:4%;
$imac-height-demo:83.5%;
$imac-width-demo:92%;


$indicator-size: 0.66rem;
$indicator-size-responsive: 0.44rem;
$walking-indicator-size-responsive: 0.44rem;

//screen width
$xwide-screen-width-min : 1400px;

$xbig-screen-width-max: 1170px;

$big-screen-width-max: 1444px;
$big-screen-width-min : 1024px;

$tablet-screen-width-max: 1024px;
$tablet-screen-width-min: 468px;

$intermediate-screen-width-max: 1024px;
$intermediate-screen-width-min:700px;

$intermediate-mobile-width-max:500px;

$mobile-screen-width-max: 1024px;
$mobile-screen-width-min: 320px;

@mixin media-breakpoint-xwide-screen() {
  @media only screen and (min-width: $xwide-screen-width-min) and (-webkit-min-device-pixel-ratio: 1) {
    @content;
  }
}

@mixin media-breakpoint-xbig-screen() {
  @media only screen and (min-width: $big-screen-width-min) and (max-width: $xbig-screen-width-max) and (-webkit-min-device-pixel-ratio: 1) {
    @content;
  }
}

@mixin media-breakpoint-big-screen() {
  @media only screen and (max-width: $big-screen-width-max) and (-webkit-min-device-pixel-ratio: 1) {
    @content;
  }
}
@mixin media-breakpoint-tablet() {
  @media only screen and (max-width: $tablet-screen-width-max) and (-webkit-min-device-pixel-ratio: 1) {
    @content;
  }
}
@mixin media-breakpoint-intermediate-max() {
  @media only screen and (max-width: $intermediate-screen-width-max) and (-webkit-min-device-pixel-ratio: 1) {
    @content;
  }
}
@mixin media-breakpoint-intermediate-min() {
  @media only screen and (max-width: $intermediate-screen-width-min) and (-webkit-min-device-pixel-ratio: 1) {
    @content;
  }
}
@mixin media-breakpoint-intermediate-mobile() {
  @media only screen and (max-width: $intermediate-mobile-width-max) and (-webkit-min-device-pixel-ratio: 1) {
    @content;
  }
}

@mixin media-breakpoint-mobile() {
  @media only screen and (max-width: $mobile-screen-width-max) and (-webkit-min-device-pixel-ratio: 1) {
    @content;
  }
}


