@import "../../../general-styles/base";

.join {
  padding: $big-margin $form-margin;
  min-height: 90vh;
  @include media-breakpoint-mobile() {
    padding: $info-margin $small-margin;
  }

  .section-text {
    margin: $small-size 0;
    color: $secondary-color;
  }

  hr {
    margin: 0 0 $medium-margin 0;
    border: 0;
    border-top: 1px solid $light-color
  }

  .positions-container {
    display: flex;
    flex-direction: column;

    .position-card {
      cursor: pointer;
      height: 5rem;
      background-color: white;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: $xsmall-margin 0;
      padding-left: $flex-gap;

      div {
        img {
          transform: rotate(180deg);
          margin-left: $xsmall-margin;

        }
      }

      &:hover {
        box-shadow: 0 5px 13px #0000001A;
      }

      .position-title {
        color: $secondary-green;
        font-weight: $font-bold;
        font-size: $input-big-size;

      }

      .position-city {
        color: $secondary-color;
        font-weight: $font-regular;
        font-size: $xsmall-size;
        margin-top:$xsmall-size;
      }
    }
  }
}

