@import '../../../general-styles/base';
@import '../../../general-styles/buttons';

//default

.contact {
  padding: $medium-margin $form-margin;
  min-height: 90vh;
}

.checkbox-container {
  svg {
    color: $primary-color;
    height: 15px;
    width: auto;
  }

  display: flex;
  align-items: center;

  span {
    padding-left: 0;
  }

  label {
    color: black !important;
  }

  .linkTo {
    color: $primary-color;
    font-weight: $font-bold;
  }

  .MuiCheckbox-colorSecondary.Mui-checked:hover,.MuiButtonBase-root:hover {
    background-color: transparent!important;
  }

}
