@import '../../../../general-styles/base.scss';

.card-shape {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc((100% - 40px) / 3);
  height: 30rem;
  background: #101A50;
  border-radius: 20px;
  box-shadow: 0 13px 21px #00000025;
  margin: 0 $small-margin 0 $small-margin;
  max-width: 430px;

  @include media-breakpoint-intermediate-min() {
    width: 33%;
    margin: $xsmall-margin 0;
  }
  @include media-breakpoint-intermediate-min() {
    width: 100%;
    margin: $xsmall-margin 0;
  }

  .card-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background: #101A50 0 0 no-repeat padding-box;
    width: 100%;
    height: 46%;
    border-radius: 20px 20px 0 0;
    opacity: 1;

    .card-main-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .card-title {
        color: #FFFFFF;
        font-family: $font-family;
        font-size: $page-title;
      }

      .card-description {
        color: #FFFFFF;
        font-family: $font-family;
        font-size: $text-size;
        font-weight: $font-light;
      }
    }
  }

  .card-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF 0 0 no-repeat padding-box;
    width: 100%;
    height: 54%;
    border-radius: 10px 10px 20px 20px;
    border: solid #101A50 2px;

    .card-price {
      display: flex;
      flex-direction: column;
      align-items: center;

      .price-value {
        color: #23BF90;
        font-size: $title-size;
        font-weight: $font-bold;
        margin: $small-margin 0 0 0;
      }

      .per-order {
        font-size: $text-size;
        margin: 0;
      }
    }

    .card-features {
      margin-top: $small-margin;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90%;
      background: #F9F9F9;
      border-radius: 5px;
      padding: $xsmall-margin $info-margin;

      .card-feature {
        display: flex;
        align-items: center;
        width: 100%;
        font-size: $xsmall-size;
        font-weight: $font-medium;
        height: 30px;

        .check-img {
          height: 35%;
          margin-left: auto;
        }
      }
    }
  }
}


