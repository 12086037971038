@import '../../../../general-styles/base';

.wrapper {
  display: flex;
  flex-direction: column;
  height: 360px;
  width: 390px;

  .image-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 60%;
    border-radius: 20px;
    border: 2px solid #f8f8f8;
    transition: 0.3s ease-out;
  }
  .image-wrapperActive {
    border-color: #101A50;
  }

  //.category {
  //  margin-top: $xsmall-margin;
  //  font: normal normal medium 15px/28px Ubuntu;
  //  color: #11C9E5;
  //  padding-right: $xsmall-margin;
  //}

  .title {
    margin-top: $xsmall-margin;
    font: normal normal bold 19px/32px Ubuntu;
    padding-right: $xsmall-margin;
    transition: 0.3s ease-out;
  }

  .titleActive {
    color: #111D54;
  }
}

.wrapper:hover {
  .image-wrapper {
    border-color: #101A50;
  }

  .title {
    color: #111D54;
  }
}