@import '../../../general-styles/base';

.thank-you {
  padding: $big-margin 0;
  align-items: center;
  min-height: 85vh;
  @include media-breakpoint-mobile() {
    padding: $big-margin $small-margin;
  }
  .section-title {
    font-size: $title-size;
  }

  .section-text {
    font-weight: $font-medium;
    font-size: $input-big-size;
    @include media-breakpoint-mobile() {
      line-height: 1.5rem;
    }
  }

  .check-img {
    margin: $big-margin 0 $small-margin 0;
  }

  .redirect {
    color: $secondary-green;
    font-size: $small-size;
    font-weight: $font-bold;
    margin: $big-margin 0;

    img {
      margin-right: $xsmall-margin;
    }
  }
}

