@import '../../../general-styles/base';

.page {
  padding: $medium-margin $xxl-margin;
  background-color: $background-color;
  @include media-breakpoint-mobile() {
    padding: $medium-margin $small-margin;
  }

  .page-title {
    text-align: left;
    font-size: $page-title;
    font-weight: $font-bold;
  }

  .subtitle {
    color: #000000;
    font-size: $info-title;
    font-weight: $font-bold;
  }

  table {
    border-collapse: collapse;
  }

  th, td {
    border: 1px solid black;
    padding: 5px;
  }
}
