@import '../../../general-styles/base.scss';

.pricing {
  max-width: 90rem;

  @include media-breakpoint-mobile() {
    padding: 0 $medium-margin
  }

  .pricing-title {
    margin-bottom: $xbig-margin
  }

  .cards-container {
    display: flex;
    width: 100%;
    @include media-breakpoint-intermediate-max() {
      flex-direction: row;
    }
    @include media-breakpoint-intermediate-min() {
      flex-direction: column;
      align-items: center;
    }
  }
}

