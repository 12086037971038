@import '../../../general-styles/base';

.image-container {
  display: flex;
  justify-content: center;
  height: 540px;

  img {
    object-fit: contain;
  }
}

.title {
  font: normal normal bold 30px/42px Ubuntu;
  margin-top: $header-margin;
}

.subtitle {
  font: normal normal bold 25px/35px Ubuntu;
  margin-top: $header-margin;
}

.text {
  font: normal normal normal 15px/20px Ubuntu;
  color: #959595;
}

.all-articles-link {
  margin-left: auto;
  margin-right: auto;
  padding-top: $header-margin;
  padding-bottom: $flex-gap;

  .text {
    color: #23BF90;
    font: normal normal bold 15px/17px Ubuntu;
  }

  .chevron {
    width: 7px;
    height: 12px;
    margin-right: $xsmall-margin;
  }
}

.all-articles-link:hover {
  .text {
    color: #16A278;
  }
}