@import '../../../general-styles/base';
@import '../../../general-styles/form';

.charCounter {
  margin: 0;
  font-family: $font-family;
  font-size: $xsmall-size;
  text-align: left;
  font-weight: $font-regular;
  visibility: hidden;
  color: #959595;
}

.charCounterActive {
  visibility: visible;
}