@import '../../../general-styles/base.scss';

.features.section {
  padding:$info-margin;
  @include media-breakpoint-mobile() {
    padding: $small-margin;
  }

  .section-text {
    margin:0.7rem 0;
    @include media-breakpoint-mobile() {
      padding: 0 $medium-margin;
    }
    &.weight-medium{
      font-size:1rem;
    }
  }

  .section-title {
    margin-bottom:$info-margin;
    @include media-breakpoint-mobile() {
      padding:0 $medium-margin;
    }
  }

  .features-list {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: $xxbig-margin;
    @include media-breakpoint-mobile() {
      display: flex;
      margin-top:$big-margin;
    }

    .feature {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 33%;
      padding: 0 $navigation-spacing $big-margin $navigation-spacing;
      @include media-breakpoint-mobile() {
        width: auto;
        padding: 0 0 $big-margin 0;
      }

      img {
        padding-right: $small-margin;
        height: 4.5rem;
      }

      .right-side-text {
        font-family: $font-family;
        line-height: 1.3rem;
        text-align: left;
        display: flex;
        flex-direction: column;
        max-width: 16.5rem;
      }

      .feature-title {
        font-size: $small-size;
        font-weight: $font-bold;
        padding-bottom: $xsmall-margin;
        @include media-breakpoint-mobile() {
          font-size:$small-size ;
        }
        @include media-breakpoint-tablet() {
          font-size:$xsmall-size ;
        }
      }

      .feature-subtitle {
        font-size: $small-size;
        font-weight: $font-light;
        @include media-breakpoint-mobile() {
          font-size:$helper-size ;
        }
        @include media-breakpoint-tablet() {
          font-size:$xsmall-size ;
        }
      }
    }
  }
}
