@import '../../../general-styles/base.scss';

.footer {
  border: 1px solid $border-color;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 $xxbig-margin;
  background-color: $background-color;
  @include media-breakpoint-mobile() {
    flex-direction: column;
    padding:$xsmall-margin 0;
  }

  .info-pages, .footer-info {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    @include media-breakpoint-mobile() {
      flex-direction: column;
      padding: $xsmall-margin 0;

    }

    a {
      margin: $medium-margin $small-margin;
      font-weight: $font-medium;
      font-size: $text-size;
      @include media-breakpoint-mobile() {
        margin:0;
        font-size: $helper-size;
          padding:$xsmall-margin 0;
      }

    }

    div {
      padding: $medium-margin $small-margin;
      font-weight: $font-regular;
      font-size: $xsmall-size;
      @include media-breakpoint-mobile() {
        padding:0;
      }

    }

    img {
      width: 1.1rem;
      margin-left:  $small-margin;
      @include media-breakpoint-mobile() {
       margin:0;
        padding-top:$info-margin;
      }

    }
  }
}
