@import '../../../general-styles/base';

.demo {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $big-margin;
  margin-top: $flex-gap;

  @include media-breakpoint-intermediate-min {
    padding-right: $xsmall-margin;
    padding-left: $xsmall-margin;
  }

  .section-text {
    font-size: $info-title;
    font-weight: $font-regular;
    margin-bottom: $flex-gap;

    @include media-breakpoint-mobile() {
      font-size: $text-size;
      margin-bottom: $small-margin;
    }
  }

  .weight-bold {
    font-size: $info-title;
    padding-bottom: $small-margin;

    @include media-breakpoint-mobile() {
      font-size: $text-size;
    }
  }
}

