@import '../../../general-styles/base.scss';

.home {
  text-align: center;
  align-items: center;
  margin: 0;
  max-width: 100%;
  height: 100%;
  position: relative;
  padding: $big-margin;

  @include media-breakpoint-intermediate-min {
    padding-right: $xsmall-margin;
    padding-left: $xsmall-margin;
  }

  .hero {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, #111B54, #253AB0);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0 0 15px 15px;
    position: absolute;
    top: 2.5rem;

    @include media-breakpoint-mobile() {
      border-radius: 0 0 10px 10px;
    }
  }

  .big-title, .big-text {
    color: white;
    z-index: 1;
  }

  .big-title {
    font-weight: $font-bold;
    font-size: $big-title-size;
    margin-top: $xbig-margin;

    @include media-breakpoint-mobile() {
      font-size: $big-title-mobile;
    }
  }

  .big-text {
    font-weight: $font-regular;
    font-size: $info-title;
    padding: $info-margin 0;
    @include media-breakpoint-mobile() {
      font-size: $text-size;
      padding: $info-margin $big-margin;
    }
  }
}

.player {
  top: $imac-top-border-demo;
  left: $imac-left-border-demo;
  margin-top: $big-margin;

  video {
    border: 10px solid #111D54;
    border-radius: 19px;
    background: #111D54;
    @include media-breakpoint-intermediate-min {
      border: 3px solid #111D54;
      border-radius: 10px;
    }

    &:focus {
      outline: none;
    }
  }
}

.hide {
  display: none;
}

.player-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

#playButton {
  position: absolute;
  height: 9%;
  width: 9%;
  bottom: 20%;
  @include media-breakpoint-intermediate-min {
    height: 12%;
    width: 12%;
    bottom: 15%;
  }

  img {
    height: 100%;
    width: 100%;
  }
}

.player {
  top: $imac-top-border-demo;
  left: $imac-left-border-demo;
  margin-top: $big-margin;

  video {
    border: 10px solid #ffffff;
    border-radius: 19px;
    background: #111D54;
    @include media-breakpoint-intermediate-min {
      border: 3px solid #111D54;
      border-radius: 10px;
    }

    &:focus {
      outline: none;
    }
  }

}

.hide {
  display: none;
}

.player-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

#playButton {
  position: absolute;
  height: 9%;
  width: 9%;
  bottom: 20%;
  @include media-breakpoint-intermediate-min {
    height: 12%;
    width: 12%;
    bottom: 15%;

  }

  img {
    height: 100%;
    width: 100%;
  }
}
