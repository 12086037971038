@import '../../../general-styles/base';

.article-section-text {
  color: #959595;
  margin-top: $xsmall-margin;
  font-weight: $font-regular;
  font-size: $text-size;
  text-align: center;
  line-height: $medium-size;
  margin-bottom: $big-margin;
  @include media-breakpoint-mobile() {
    font-size: $helper-size;
    line-height: 1rem;
  }
  @include media-breakpoint-mobile() {
    font-size: $small-size;
    line-height: 1.3rem;
  }
}

.article-section-title {
  margin-top: $info-margin;
  margin-bottom: 0;
}