@import '../../../general-styles/base.scss';

.is-rapid-donkey-for-you {
  @include media-breakpoint-mobile() {
    padding: 0 $medium-margin
  }

  .cards-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
   @include media-breakpoint-intermediate-max(){
     flex-direction: row;
   }
    @include media-breakpoint-intermediate-min() {
      flex-direction: column;
      align-items: center;
    }

    .image-card {
      position: relative;
      border-radius: 10px;
      width: calc((100% - 40px) / 3);
      max-width: 360px;
      cursor: default;
      @include media-breakpoint-intermediate-min() {
        width: 33%;
        margin: $xsmall-margin 0;
      }
      @include media-breakpoint-intermediate-min() {
        width: 100%;
        margin: $xsmall-margin 0;
      }

      img {
        width: 100%;
        height: 100%
      }

      .card-hover {
        display: flex;
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        background-image: url('../../../assets/images/card-back.svg');
        background-position: left;
        background-size: cover;
        border-radius: 10px;
        color: white;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: $medium-margin;
        transition: opacity 0.2s;
        //to change font to helvetica
        font-size: $info-title;
        @include media-breakpoint-intermediate-max() {
          font-size: $card-hover-size;
        }
        @include media-breakpoint-intermediate-min() {
           font-size: $info-title;
         }

          p {
          font-weight: $font-light;
        }

        span {
          font-weight: $font-bold;
        }
      }
    }
  }

  .image-card {
    .card-hover {
      opacity: 0;
    }

    &:hover {
      .card-hover {
        opacity: 1;
      }
    }
  }
}

