@import '../../../general-styles/base';

.custom-dropzone {
  height: 2.2rem;
  background-color: #fff;
  border: 1px dashed $medium-color;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-size: $xsmall-size;
  margin-top: $xsmall-size;

  &.pointer-events-none {
    pointer-events: none;
  }

  .drop-icon {
    height: 1.1rem;
    padding: 0;
    margin-right: $small-margin;
  }

  .file-input {
    display: none;
  }

  .grey-text {
    font-size: $xsmall-size;
    &.mobi{
      display: none;
    }
    &.desktop{
      display:block;
    }
    @include media-breakpoint-mobile() {
      &.mobi{
        display:block;
      }
      &.desktop{
        display:none;
      }
    }

  .weight-bold {
    color: $primary-color
  }
}

.drop-container {
  &.flex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.file-name-container {
  background: $drop-background;
  height: 80%;
  width: 99%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: $xsmall-margin;
  div{
    color: $dark-color;
    font-weight: $font-bold;
    font-size: $xsmall-size;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

}
.drop-error{
  border:1px dashed $error-color;

}
