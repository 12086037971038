@import "./base.scss";

.button {
  border-radius: 5px;
  text-align: center;
  font-weight: $font-medium;
  font-size: $small-size;
  text-transform: uppercase;
  text-align: center;
  user-select: none;
  cursor: pointer;
  padding: $button-padding;

  &.blue {
    background-color: $primary-color;
    color: white;
    transition: box-shadow 0.15s, background-color 0.05s;

    &:hover {
      background-color: lighten($primary-color, 3%);
      box-shadow: 0px 5px 13px #0000004D;    }

    &:focus {
      box-shadow: 0 3px 7px rgba(#000033, 0.3);
      background-color: darken($primary-color, 3%);
      transition: box-shadow 0.1s, background-color 0.1s;
    }

    &:active {
      box-shadow: 0 1px 3px rgba(#000033, 0.5);
      background-color: darken($primary-color, 7%);
      transition: box-shadow 0.1s, background-color 0.05s;
    }
  }

  &.transparent {
    background-color: transparent;
    color: $primary-color;
    box-shadow: 1px 1px 1px 1px inset $primary-color;

  }

  &.default-hover{
    box-shadow: 0px 5px 13px #00000033;
  }

  &.small {
    width: 6rem;
  }

  &.medium {
    width: 8rem;
  }

  &.large {
    width: 10.1rem;
  }

  &.xlarge{
    width:11.5rem;
  }
}
