@import "./base.scss";

* {
  box-sizing: border-box;
}

body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  border: none;
}

html {
  font-size: 18px;
  font-family: $font-family;
}

button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

input, textarea, button {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  box-shadow: none;
  outline: none;
  resize: none;
  padding: 0;

  &:hover, &:active {
    outline: none;
  }
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }
}

h1, h2, h3 {
  margin-top: 0;
}

body {
  background-color: $background-color;
}

div:focus {
  outline: none;
}

li {
  margin-top: $xsmall-margin;
}

.section {
  display: flex;
  flex-direction: column;
  max-width: 80rem;
  width: 100%;
  margin: $xbig-margin auto 0 auto;
  padding: 0 $xxl-margin;
  @include media-breakpoint-mobile() {
    padding: 0 $small-margin;
  }

  .section-title {
    font-weight: $font-medium;
    font-size: $title-size;
    text-align: center;
    @include media-breakpoint-mobile() {
      font-size: $info-title;
    }
  }

  .big-size {
    font-size: $title-size;
    font-weight: $font-bold;
    @include media-breakpoint-mobile() {
      font-size: $big-title-mobile;
    }
  }

  .section-text {
    font-weight: $font-regular;
    font-size: $text-size;
    text-align: center;
    line-height: $medium-size;
    margin-top: $medium-margin;
    margin-bottom: $big-margin;
    @include media-breakpoint-mobile() {
      font-size: $helper-size;
      line-height: 1rem;
    }
    @include media-breakpoint-mobile() {
      font-size: $small-size;
      line-height: 1.3rem;

    }
  }

  .info-title {
    font-size: $info-title;
    font-weight: $font-bold;
    margin-top: $info-margin;
  }

  .grey-text {
    font-size: $small-size;
    color: $secondary-color;
    text-align: left;
    font-weight: $font-regular;
    line-height: $medium-size;
  }

  .weight-light {
    font-weight: $font-light;
  }

  .weight-bold {
    font-weight: $font-bold;
  }
  .weight-medium {
      font-weight: $font-medium;
    }

  hr {
    margin: 0 0 $medium-margin 0;
    border: 1px solid $light-color;
    height: 1px;
    width: 100%;
  }
}

.hide {
  display: none;
}

.loading-container {
  height: 50vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  object-fit: contain;
}
.homepage{
  overflow:hidden;
}
