@import '../../../general-styles/base.scss';

.image-sections {
  padding: 0;
  margin-top: 0;
  @include media-breakpoint-mobile() {
    padding: 0 $small-margin;
  }

  .section-container {
    display: flex;
    margin-top: $big-margin;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-mobile() {
      margin-top: $medium-margin;
    }

    &.left {
      flex-direction: row;
      @include media-breakpoint-mobile() {
        flex-direction: column-reverse;
      }

      .text-container {
        padding: 0 $xxbig-margin 0 $medium-margin;
        @include media-breakpoint-mobile() {
          padding: 0;
        }

      }

    }

    &.right {
      flex-direction: row-reverse;
      @include media-breakpoint-mobile() {
        flex-direction: column-reverse;
      }

      .text-container {
        padding: 0 $medium-margin 0 $xxbig-margin;
        @include media-breakpoint-mobile() {
          padding: 0;
        }

      }
    }

    img {
      max-width: 49rem;
      width: 65%;
      @include media-breakpoint-mobile() {
        width: 100%;
        margin: $small-margin 0;
      }
    }

    .text-container {
      @include media-breakpoint-mobile() {
        margin-top: 0;
        padding: 0;
        text-align: center;
        display: flex;
        flex-direction: column;
      }

      p.section-text {
        margin-top: $small-margin;
        margin-bottom: 0;
        text-align: left;
        padding: 0;
        @include media-breakpoint-mobile() {
          text-align: center;
          margin-top: $paragraph-spacing-mobile
        }
      }

      .section-title {
        @include media-breakpoint-mobile() {
          padding-bottom: $small-margin;
        }
      }
    }

  }
}
