@import '../../../general-styles/base.scss';

.our-apps {
  text-align: center;
  align-items: center;
  margin: 0;
  max-width: 100%;
  height: 100%;
  position: relative;
  padding: $big-margin;

  @include media-breakpoint-intermediate-min {
    padding-right: $xsmall-margin;
    padding-left: $xsmall-margin;
  }

  .our-apps-title {
    margin-top: $medium-margin
  }
}
