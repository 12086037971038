@import '../../../general-styles/base.scss';

.about-us {
  margin-top: $flex-gap;

  .section-title {
    margin-bottom: $xsmall-margin;
  }

  .section-text {
    margin-top: $small-margin;
    margin-bottom: 0;
    text-align: center;

    .bold {
      font-weight: $font-bold;
    }
  }

  img {
    margin-top: $medium-margin;
    @include media-breakpoint-mobile() {
      width: 100%;
    }
  }
}

